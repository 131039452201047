<template>
  <App
    left-arrow
    title="Leads"
    class="allSearch"
  >
    <Tabs
      v-model="tabActive"
      slot="extra"
      @change="tableChange"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
      </Tab>
    </Tabs>
    <PullRefresh
      @refresh="onRefresh"
      v-model="loading"
    >
      <List
        v-model="loading"
        :immediate-check="false"
        :error.sync="error"
        :finished="finished"
        :finished-text="total === 0 ? '暂无数据!' : 'No more items.!'"
        error-text="数据加载失败! 点击重试"
        @load="Onload"
      >
        <CardItem
          v-for="$items in list"
          :key="$items.id"
          :cardInfo="$items"
          :type="tabActive"
        />
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { Tab, Tabs, PullRefresh, List, Toast } from 'vant'
import { tabsData } from './attr.js'
import { leadsTodayList, leadsWaitContactList, leadsDormantList } from '@/api/follow/index'
import { getSource } from '@/api/chargequery'
import CardItem from './components/card/index'
import 'vant/lib/nav-bar/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'cluesFollowUp',
  data () {
    return {
      loading: false,
      finished: false,
      error: false,
      list: [],
      tabActive: this.$route.query.tabName,
      tabsData: [],
      page: 1,
      size: 10,
      total: 0,
      leadsStatusMap: null, // 状态
      clueSourceMap: null
    }
  },
  components: {
    Tab,
    Tabs,
    CardItem,
    List,
    PullRefresh,
  },
  methods: {
    getData: debounce(async function (refresh = false) {
      if (this.finished) return
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const params = {
        page: this.page,
        size: this.size,
        currentIdentity: this.$route.query.currentIdentity
      }

      switch (this.tabActive) {
        case 'followUp': // 今日待跟进
          this.handleRequest(leadsTodayList(params), refresh)
          break;
        case 'contact': // 待联系
          this.handleRequest(leadsWaitContactList(params), refresh)
          break;
        case 'dormancy': // 休眠
          this.handleRequest(leadsDormantList(params), refresh)
          break;
      }
    }, 600, { leading: true, trailing: false }),

    // 获取线索来源
    async getSource () {
      const res = await getSource()
      if (res.success) {
        this.clueSourceMap = res.data.reduce((res, cur) => {
          res[cur.threelevelCode] = cur.threelevelName
          return res
        }, {})
      }
    },
    getStatus () {
      this.leadsStatusMap = this.$storage.get('baseData')['leads_status'].reduce((res, cur) => {
        res[cur.value] = cur.key
        return res
      }, {})
    },
    handleRequest (promised, refresh = false) {
      promised
        .then(res => {
          Toast.clear()
          if (res.success) {
            this.formatResponseData(res.data.records)
            this.total = res.data.total
            this.list = refresh ? res.data.records : [...this.list, ...res.data.records]
            if (this.list.length === this.total) {
              this.finished = true
            } else {
              this.page++
            }
            this.error = false
          } else {
            this.error = true
            Toast('数据加载失败!')
          }
        })
        .catch(err => {
          Toast.clear()
          console.log(err)
          this.error = true
        })
        .finally(() => {
          Toast.clear()
          this.loading = false
        })
    },
    formatResponseData (data) {
      data.forEach(el => {
        el.qbsFollowupTime = el.qbsFollowupTime ? dayjs(el.qbsFollowupTime).format('YYYY/MM/DD') : ''
        el.threeLevelChannel = this.clueSourceMap[el.threeLevelChannel]
        el.status = this.leadsStatusMap[el.status]
      })
    },
    tableChange () {
      this.page = 1
      this.total = 0
      this.finished = false
      this.list = []
      this.getData()
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.getData(true)
    },
    Onload () {
      this.getData()
      this.loading = false
    },
    async init () {
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.tabsData = tabsData
      this.tabActive = this.$route.query.tabName
      this.getStatus()
      await this.getSource()
      this.getData()
    }
  },
  mounted () {
    this.init()
  }
}
</script>